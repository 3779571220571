import React, { useState, useEffect } from "react";
import { Grid, Card } from "@material-ui/core";
import DataService from "../services/api/data_services";
import Megaview from "../templates/MegaView";
import Layout from "../components/layouts/layout";
import Typography from "@material-ui/core/Typography";
import clutchLogo from "../assets/Images/WhatWeDo/clutch-co-vector-logo.svg";
import RightArrow from "../assets/Icons/go.svg";
import RightArrowWhite from "../assets/Icons/accordian_white.svg";
import BackArrowIcon from "../assets/Images/accordian.svg";
import Button from "../components/Button";
import MiniImageBannerBgImg12 from "../assets/Images/HowWeDoIt/who_we_work_with.png";
//Image Banner
import ImageBanner from "../templates/ImageBanner";
import BannerBgImage from "../assets/Images/ProductDesigningAndPrototyping/product_designing_and_prototyping.png";
import MobileSmoothSlider from "../components/MobileSmoothSlider";
import { BASE_SITE_URL } from "../shared/constants";
//Info View
import Infoview from "../templates/InfoView";

//Image List
import ImageList from "../templates/ImageList";
import ImageListDynamic from "../templates/ImageListDynamic";

//Miniview
import Miniview from "../templates/MiniView";

// Two Column Contact info
import TwoColumnContactInfo from "../templates/TwoColumnContactInfo";
import Clm1Img from "../assets/Icons/phone.svg";
import Clm2Img from "../assets/Icons/mail.svg";

//Mini Image Banner
import MiniImageBanner from "../templates/MiniImageBanner";
import MiniImgBnrBgImg from "../assets/Images/ProductDesigningAndPrototyping/product_designing_and_prototyping_mini.png";

//BlurCardList
import BlurCardList from "../templates/BlurCardList";
import MiniCardBgImage1 from "../assets/Images/Homepage/mobile_application_development.png";
import MiniCardBgImage2 from "../assets/Images/Homepage/web_application_development.png";
import MiniCardBgImage3 from "../assets/Images/Homepage/full_product_development.png";
import MiniCardBgImage4 from "../assets/Images/Homepage/MVP.png";
import MiniCardBgImage6 from "../assets/Images/Homepage/product_scaling.png";
import MiniCardBgImage7 from "../assets/Images/Homepage/code_ux_audit.png";
import MiniCardBgImage8 from "../assets/Images/Homepage/digital_transformation.png";
import MiniCardBgImage9 from "../assets/Images/Homepage/machine_learning.png";

//Click To View Card
import ClickToViewCard from "../templates/ClickToViewCard";
import ClickToViewCardImg1 from "../assets/Images/ClickToViewCard/Business-Model-Valuation.png";
import ClickToViewCardImg2 from "../assets/Images/ClickToViewCard/Proof-of-Concept.png";
import ClickToViewCardImg3 from "../assets/Images/ClickToViewCard/Prototype.png";
import ClickToViewCardImg4 from "../assets/Images/ClickToViewCard/Minimum_Viable_Product.png";

//Mini Image Banner1
import MiniImageBanner1 from "../templates/MiniImageBanner1";

//HoverCardWithButton
import HoverCardWithButton from "../components/HoverCardWithButton";
import hoverCardImg1 from "../assets/Images/ProductDesigningAndPrototyping/delivery.svg";
import hoverCardImg2 from "../assets/Images/ProductDesigningAndPrototyping/process.svg";
import hoverCardImg3 from "../assets/Images/ProductDesigningAndPrototyping/output.svg";
// Mobile movie slider
import MobileMovieSlider from "../components/MobileMovieSlider";
import awardsForMobile from "../assets/Images/About Us/awards____2x.png";
import scrollImage from "../assets/Images/ProductDesigningAndPrototyping/1_2x.png";
import ogimage from "../assets/Images/ogImages/OG_Prod-design-prototype.png";

const ProductDesigningAndPrototyping = () => {
  const [page, setPage] = useState(1);
  const [awardsData, setAwardsData] = useState([]);
  const [limit, setLimit] = useState(6);
  const [articleRawData, setArticleRawData] = useState([]);
  const [articleLimit, setArticleLimit] = useState(6);
  const [articlePage, setArticlePage] = useState(1);
  const [articleData, setArticleData] = useState([]);
  const [megaviewData, setMegaViewData] = useState([]);
  const [clientStoryData, setClientStoryData] = useState([]);
  const [clientStoryRawData, setClientStoryRawData] = useState([]);
  const [isHover, setIsHover] = useState(false);
  const toggleHover = () => {
    setIsHover(!isHover);
  };
  useEffect(() => {
    DataService.getData(
      "/admin/api/all-services?limit=1&type=White Paper&service_type=PD - Product Designing %26 Prototyping"
    ).then((response) => {
      setMegaViewData(response);
    });
    DataService.getData(
      "/admin/api/all-services?type=Awards&service_type=PD - Product Designing %26 Prototyping&limit=5"
    ).then((response) => {
      setAwardsData(response.data);
    });
  }, []);

  useEffect(() => {
    DataService.getData(
      `/admin/api/all-services?limit=${articleLimit}&page=${articlePage}&type=Article&service_type=PD - Product Designing %26 Prototyping&component=mini_view`
    ).then((response) => {
      setArticleData([...articleData, ...response.data]);
      setArticleRawData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleLimit, articlePage]);
  useEffect(() => {
    DataService.getData(
      `/admin/api/all-services?limit=${limit}&page=${page}&type=Client Story&service_type=PD - Product Designing %26 Prototyping&component=mini_view`
    ).then((response) => {
      setClientStoryData([...clientStoryData, ...response.data]);
      setClientStoryRawData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit]);
  /**
   * Image Banner
   */
  const ImageBannerData = {
    bnrImg: BannerBgImage,
    bnrImgTitle: (
      <div>
        Product <br /> Designing and <br />
        Prototyping
      </div>
    ),
    bnrImgSubTitle: "How can we help you today?",
    servicesColumn1: {
      data: [
        {
          service: "Mobile Application Development",
          link: "/mobile-application-development",
        },

        {
          service: "MVP Development",
          link: "/mvp-development",
        },
        {
          service: "Code & UX Audit",
          link: "/code-ux-audit",
        },
      ],
    },
    servicesColumn2: {
      data: [
        {
          service: "Web Application Development",
          link: "/web-application-development",
        },
        {
          service: "Product Designing and Prototyping",
          link: "/product-designing-and-prototyping",
        },
        {
          service: "Digital Transformation",
          link: "/digital-transformation",
        },
      ],
    },
    servicesColumn3: {
      data: [
        {
          service: "Full Product Development",
          link: "/full-product-development",
        },
        {
          service: "Product scaling",
          link: "/product-scaling",
        },
        {
          service: "Machine Learning",
          link: "/machine-learning",
        },
      ],
    },
  };
  /**
   * Infoview1
   */
  const InfoviewData1 = {
    InfoviewTitle: (
      <div className="ttc">
        <span className="red-line3letter">The</span> Start Of Your Product
        Journey
      </div>
    ),
    tag: "h2",
    InfoviewContent: (
      <>
        Good product design creates a visual language that drives user
        engagement, making your product easier to market. We design and develop
        innovative app prototypes, to help demonstrate the product-market fit.
        The more you learn before the product or feature is released, the more
        confidence you gain in improving your product. With the prototyping
        process, you can devote more time to innovate for your next release and
        address customer complaints.
      </>
    ),
  };
  /**
   * Infoview1
   */
  const InfoviewData2 = {
    InfoviewTitle: (
      <div className="ttc">
        <span className="red-line3letter">Our</span> Experience Building
        Prototypes
      </div>
    ),
    tag: "h4",
    InfoviewContent: (
      <>
        Embracing prototyping at the design phase helps cover you against market
        indifference, customer backlash, and overwhelming support costs. At
        Digiryte, we have created over hundreds of prototypes number of
        Prototypes for our clients across the globe. Here are some of the
        interesting case studies that will highlight our expertise in creating
        Prototypes.
      </>
    ),
  };
  /**
   * Infoview
   */
  const InfoviewData = {
    InfoviewTitle: (
      <div className="ttc">
        <span className="red-line3letter">Our</span> Offerings
      </div>
    ),
    tag: "h3",
    InfoviewContent: (
      <>
        Digiryte’s prototyping team delivers value for your business from idea
        to digital prototype delivery. We provide all aspects of digital
        prototyping, from consultation, ideation, design and development of your
        digital products.
      </>
    ),
  };

  /**
   * Imagelist
   */

  const ImagelistData = {
    ImageListTitle: (
      <div className="ttc">
        Awards &<br />
        <span className="red-line3letter">Rec</span>ognitions
      </div>
    ),
    tag: "h4",
  };

  /**
   * TwoColumnContactInfo
   */

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };

  /**
   * Mini Image Banner
   */

  const miniImageBannerData = {
    Id: "minibanner1",
    fontSize: "56px",
    inlineStyle: "yes",
    MiniImageBannerBgImg: MiniImgBnrBgImg,
    MiniImageBannerAltTxt: "Contact Us",
    route: "/contact-us",
    HoverLinkContent: (
      <div>
        Want to turn your ideas <br /> into a working prototype?
      </div>
    ),
    underLine: "no",
  };

  /**
   * Click To View Card
   */

  const ClickToViewCardData = [
    {
      title: "Agile Mindset",
      heading: (
        <>
          <span className="black-line3letter">Agi</span>le Mindset
        </>
      ),
      content:
        "An Agile Mindset provides one of the biggest opportunities to work quickly, respond faster to customer needs and reduce the cost of change. Based on your business model, budget, team, objectives and stakeholders involved, we help you determine the best agile process to adopt for your project.",
      image: ClickToViewCardImg1,
      MediumviewButtonColor: "primary",
      MediumviewButtonLabel: "Find Out More",
      route: "/contact-us",
    },
    {
      title: "Experience",
      heading: (
        <>
          <span className="black-line3letter">Exp</span>erience
        </>
      ),
      content:
        "Our experienced team at Digiryte will help you through the entire process of digital prototyping. By understanding the potential challenges faced by customers, we will help you to adopt one of the most viable designs to build a prototype app. We help design and develop customised prototypes specifically to meet your business needs.",
      image: ClickToViewCardImg2,
      MediumviewButtonColor: "primary",
      MediumviewButtonLabel: "Find Out More",
      route: "/contact-us",
    },
    {
      title: "Design Thinking",
      heading: (
        <>
          <span className="black-line3letter">Des</span>ign Thinking
        </>
      ),
      content:
        "One of the most important frameworks for product development is a human-centered, interactive learning process called Design Thinking. This process focuses on customers as people with defined needs and works backwards towards a technology solution. We use design thinking as a primary step before releasing a product, as this step provides clarity on  business objectives and a good understanding of how the company's products are positioned on the market.",
      image: ClickToViewCardImg3,
      MediumviewButtonColor: "primary",
      MediumviewButtonLabel: "Find Out More",
      route: "/contact-us",
    },
    {
      title: "Customer Satisfaction",
      heading: (
        <>
          <span className="black-line3letter">Cus</span>tomer Satisfaction
        </>
      ),
      content:
        "Depending on the complexity of the project and customer preference, we can help you in understand the variety of options available to present planned functionality of your product to its recipient. From building a simple product design of the UI to creating a proof of concept prototype that makes it easier for a customer to imagine how your product will look and work at the end.",
      image: ClickToViewCardImg4,
      MediumviewButtonColor: "primary",
      MediumviewButtonLabel: "Find Out More",
      route: "/contact-us",
    },
  ];

  /**
   * Blur Card List Data
   */

  const BlurCardListData = {
    cardsData: [
      {
        image: MiniCardBgImage1,
        title: "Mobile Application Development",
        subTitle:
          "Take business to your customers by building advanced native and hybrid mobile apps for both Android and iOS Platforms.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/mobile-application-development",
      },
      {
        image: MiniCardBgImage2,
        title: "Web Application Development",
        subTitle:
          "Accelerate different functions of your business by creating custom web applications using the latest in digital technologies.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/web-application-development",
      },
      {
        image: MiniCardBgImage3,
        title: "Full Product Development",
        subTitle:
          "The process of full product development encompasses all steps needed to take your product from concept to market place.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/full-product-development",
      },
      {
        image: MiniCardBgImage4,
        title: "MVP Development",
        subTitle:
          "Build a basic model to demonstrate the core functionality of your product, test it with early adopters and fulfil the primary goal of your business.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/mvp-development",
      },
      {
        image: MiniCardBgImage6,
        title: "Product Scaling",
        subTitle:
          "Found your product-market fit? Great! Let us help you with a profitable scaling process that provides both sustainability and stability for your product.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/product-scaling",
      },
      {
        image: MiniCardBgImage7,
        title: "Code & UX Audit",
        subTitle:
          "With a regular audit, we can help you fix your product, improve its performance, gain new users, and boost your brand reputation in the marketplace.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/code-ux-audit",
      },
      {
        image: MiniCardBgImage8,
        title: "Digital Transformation",
        subTitle:
          "Update your traditional business processes and customer experience to meet the ever-changing market requirements with the help of next-generation digital technologies.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/digital-transformation",
      },
      {
        image: MiniCardBgImage9,
        title: "Machine Learning",
        subTitle:
          "Through machine learning, we can help you make informed decisions to improve business scalability and operational efficiency.",
        customClass: "effect-btn no-padding-left mt-20",
        btnLabel: "Learn More",
        route: "/machine-learning",
      },
    ],
    lastCardData: {
      title: "Let's Talk",
      subTitle: "We'd love to help with your product.",
      variant: "contained",
      label: "GET IN TOUCH",
      customClass: "miniCrdBgImg-main-border",
      route: "/contact-us",
    },
  };

  /**
   * Mini Image Banner 1
   */

  const MiniImageBanner1Data = {
    title: (
      <>
        Who we work <br /> with
      </>
    ),
    titleClass: "mini-image-banner-title-area3 title-header2",
    image: MiniImageBannerBgImg12,
    altTxt: "Who we work with",
    btnClassName: "takelook-image-banner-resize-btn",
    btnLabel: "TAKE A LOOK",
    btnVariant: "contained",
    btnColor: "primary",
    route: "/who-we-work-with",
    underline: true,
    tooltip: "yes",
    tip: "TAKE A LOOK",
    underlineClass: "black-line",
  };

  /**
   * Hover card button
   */

  const hoverCardButtonData = [
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle: "Delivery",
      HoverCardWithBtnIcon: hoverCardImg1,
      HoverCardWithBtnIconAlt: "Delivery",
      HoverCardIconClass: "mb-10",
      HoverCardWithBtnsubtitle: "1-2 weeks",
    },
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle: "Processes",
      HoverCardWithBtnIcon: hoverCardImg2,
      HoverCardWithBtnIconAlt: "Processes",
      HoverCardIconClass: "mb-10",
      HoverCardWithBtnsubtitle: "Design Sprints, Design Thinking, Agile/Scrum",
    },
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle: "Output",
      HoverCardWithBtnIcon: hoverCardImg3,
      HoverCardWithBtnIconAlt: "Output",
      HoverCardIconClass: "mb-10",
      HoverCardWithBtnsubtitle: "A clickable prototype ready for testing",
    },
  ];
  const quotedMiniCardData = {
    title: "Mobile App Dev for Automative Sales App",
    heading:
      "We can’t speak highly enough of Digiryte’s team because they bent over backward to help us launch our product.",
    content: "Founder, Bumper UK",
    footerContent: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>Authentic client review from</div>
        <div style={{ paddingLeft: "5px" }}>
          <img src={clutchLogo} alt="Clutch" height="50px" />
        </div>
      </div>
    ),
  };

  const miniviewDataArticle = {
    height: "490",
    service: "machineLearning",
    title: "Let's Talk",
    subTitle: "We'd love to help with your product.",
    variant: "contained",
    label: "GET IN TOUCH",
    tooltip: "yes",
    tip: "GET IN TOUCH",
    customClass: "miniCrdBgImg-main-border",
    route: "/contact-us",
  };
  const miniviewDataCaseStudy = {
    height: "490",
    service: "machineLearning",
    title: "Let's Talk",
    subTitle: "We'd love to help with your product.",
    variant: "contained",
    label: "GET IN TOUCH",
    tooltip: "yes",
    tip: "GET IN TOUCH",
    customClass: "miniCrdBgImg-main-border",
    route: "/contact-us",
  };
  //Movie Slider
  const movieSlider1 = {
    imageUrl: scrollImage,
  };
  // Mobile movie slider
  const mobMovieSlider = {
    imageUrl: awardsForMobile,
  };

  return (
    <Layout
      seo={{
        title: "Product Designing and Prototyping | Digiryte",
        ogtitle: "Product Designing and Prototyping | Digiryte",
        description:
          "Digiryte specializes in product designing and prototyping, creating visually engaging products that drive user engagement and ease of marketing.",
        ogimage: ogimage,
        ogurl: `${BASE_SITE_URL}/product-designing-and-prototyping`,
        keywords:
          "product designing, prototyping, user engagement, visual design, marketable products, Digiryte",
      }}
    >
      <ImageBanner imageBannerData={ImageBannerData} />
      <div className="mt-50 body-container">
        <Button
          icon={
            <img
              className="effect-btn-svgicon"
              src={BackArrowIcon}
              alt="click"
            />
          }
          customClassName="effect-btn"
          className="mui-button-font"
          tip="What we do"
          routepath="/what-we-do"
        />
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "600",
            marginTop: "-42px",
            marginLeft: "40px",
          }}
        >
          What we do
        </Typography>
      </div>
      <div className="mt-30"></div>
      <Infoview InfoviewData={InfoviewData1} />
      <div className="body-container mt-70">
        <h2 className="title-header ttc">
          <span className="red-line3letter">Our</span> Prototyping Process
        </h2>
      </div>
      <div className="mt-30 body-container">
        <ClickToViewCard data={ClickToViewCardData} />
      </div>
      <div className="mt-50">
        <Infoview InfoviewData={InfoviewData} />
      </div>
      <div className="body-container">
        <Grid className=" hide-in-mobile" container direction="row" spacing={3}>
          {hoverCardButtonData.map((item, index) => (
            <Grid key={index} item xs={12} sm={6} md={4}>
              <Card className="appsgridshadow">
                <HoverCardWithButton
                  componentName="product-design"
                  HoverCardWithBtnCustomClass={item.HoverCardWithBtnCustomClass}
                  HoverCardWithBtntitle={item.HoverCardWithBtntitle}
                  HoverCardWithBtnIcon={item.HoverCardWithBtnIcon}
                  HoverCardWithBtnIconAlt={item.HoverCardWithBtnIconAlt}
                  HoverCardIconClass={item.HoverCardIconClass}
                  HoverCardWithBtnsubtitle={item.HoverCardWithBtnsubtitle}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
      <div className="show-only-in-mobile">
        <MobileSmoothSlider movieSlider={movieSlider1} />
      </div>
      <div className="mt-70"></div>
      <MiniImageBanner miniImageBannerData={miniImageBannerData} />
      <div className="mt-70">
        <div className="mt-70"></div>
        <Infoview InfoviewData={InfoviewData2} />
        {clientStoryData && clientStoryData.length > 0 && (
          <div className="mt-50">
            <Miniview
              miniviewData={clientStoryData}
              total={clientStoryData.total}
              miniviewData2={miniviewDataCaseStudy}
              quotedMiniCardData={quotedMiniCardData}
            />
          </div>
        )}
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setPage(page === 1 ? page + 2 : page + 1);
              setLimit(3);
            }}
            style={{ display: "inline-block" }}
          >
            {clientStoryRawData.has_more && (
              <Button
                icon={
                  <img
                    className="effect-btn-svgicon"
                    src={!isHover ? RightArrow : RightArrowWhite}
                    alt="MORE CLIENT STORIES"
                  />
                }
                customClassName="info-view-resize-btn"
                color="primary"
                variant="outlined"
                label="MORE CLIENT STORIES"
              />
            )}
          </div>
        </div>
      </div>
      <div className="hide-in-mobile">
        <ImageListDynamic
          ImagelistData={awardsData.length > 0 ? ImagelistData : ""}
          awardsData={awardsData}
        />
      </div>
      <div className="hide-in-web body-container">
        <h3 className="title-header mt-70 ">Awards & Recognitions</h3>
      </div>
      <MobileMovieSlider movieSlider={mobMovieSlider} slideid="slide1" />
      <div className="mt-70 body-container">
        <Megaview
          MegaviewData={megaviewData.data}
          total={megaviewData.total}
          quotedMiniCardData={quotedMiniCardData}
        />
      </div>
      <div className="mt-70">
        <MiniImageBanner1
          id="one"
          MiniImageBanner1Data={MiniImageBanner1Data}
        />
      </div>

      {articleData && articleData.length > 0 && (
        <div className="mt-70">
          <div className="body-container">
            <h4 className="title-header ttc">
              <span className="red-line3letter">Rec</span>ommended Reading
            </h4>
          </div>
          <div className="mt-50">
            <Miniview
              miniviewData={articleData}
              total={articleData.total}
              miniviewData2={miniviewDataArticle}
              quotedMiniCardData={quotedMiniCardData}
            />
          </div>
        </div>
      )}
      {articleRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setArticlePage(
                articlePage === 1 ? articlePage + 2 : articlePage + 1
              );
              setArticleLimit(3);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE ARTICLES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="READ MORE"
              tip="READ MORE"
            />
          </div>
        </div>
      )}
      <div className="body-container mt-70">
        <h4 className="title-header ttc">
          <span className="red-line3letter">Oth</span>er Services
        </h4>
      </div>
      <div className="mt-50">
        <BlurCardList componentId="1" BlurCardListData={BlurCardListData} />
      </div>
      <div className="mt-70"></div>
      <TwoColumnContactInfo
        TwoColumnContactInfoData={TwoColumnContactInfoData}
      />
    </Layout>
  );
};

export default ProductDesigningAndPrototyping;
